@import '../../master.module.scss';

$footerHeight: 69px;

.footer {
  background-color: $ColorWhite !important;
  width: 100%;
  height: $footerHeight;
  box-shadow: inset 0px 1px 0px #ECECEC;
  position: absolute;
  bottom: 0;
  color: $Color_Font_Gray;
  font-size: 14px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.footer a {
  color: $Color_Font_Gray;
}

.imprint > a:nth-child(2) {
  margin-left: 24px;
  margin-right: 24px;
}
