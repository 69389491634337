@import './footer.module.scss';

.body, .root {
  height: 100vh;
}

.app-shell {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  min-height: 100vh;
  height: 100%;
}

.app-shell .app-shell-content {
  flex: 1 1 auto;
  margin-bottom: $footerHeight;
}

.pad-bot-3 {
  padding-bottom: 3px;
}

nav.navbar {
  padding: 0;
  background-color: white !important;
}

a.navbar-brand {
  display: flex;
}

a.navbar-brand .separator {
  width: 1px;
  height: 40px;
  background-color: #dedfe0;
  margin: 0 15px;
}

.navbar-toggler {
  padding: 0.25rem 0.5rem;
}

.navbar-links a.nav-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.navbar-links a.nav-link .nav-link-img,
.dropdown-item .nav-link-img {
  display: inline-block;
  margin-right: 8px;
  opacity: 0.5;
}

.navbar .list-group-item-secondary {
  color: inherit;
  background-color: inherit;
}

.navbar .dropdown-item {
  padding: 0.25rem 0px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.rbt-menu .dropdown-menu [role="option"] {
  padding: 0.25rem 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.rbt-menu .dropdown-item span {
  display: inline-block;
  white-space: normal;
}

/* Tablet & Desktop (>xs) */
@media screen and (min-width: 576px) {
  .navbar-brand .separator {
    margin: 0 25px;
  }

  .navbar-links li.nav-item:not(:first-child) {
    margin-left: 30px;
  }

  nav.navbar {
    padding: 0.75rem 2rem;
  }

  .navbar .dropdown-menu {
    padding-left: 10px;
  }
}

/* <= Handy (xs, s) */
@media screen and (max-width: 576px) {
  .navbar .list-group-item-secondary {
    color: #383d41;
    background-color: #ececec;
  }


  .navbar .navbar-brand {
    margin: 0.75rem 1rem;
  }

  .navbar .dropdown-toggle::after {
    position: absolute;
    right: 30px;
  }

  .navbar .navbar-toggler {
    padding: 0.25rem 0.5rem;
    margin: 0.75rem 1rem 0.75rem 0.25rem;
    border: none;
  }
  
  .navbar .navbar-toggler-icon {
    height: 20px; 
    width: 20px;
  }
  
  .navbar .nav-item {
    padding-left: 1.5rem;
  }
  
  .navbar .dropdown-menu {
    background-color: inherit;
    border: none;
    padding-top: 0px;
  }
}

/* < Handy (xs) */
@media screen and (max-width: 383px) {
  .navbar-brand .extra-logo {
    display: none;
  }

  .navbar-brand .separator {
    display: none;
  }
}

.btn-primary {
  background-color: #467fcf !important;
  border-color: #467fcf !important;
}

.btn:disabled {
  background-color: #ececec !important;
  border-color: #ececec !important;
}

.btn:focus {
  box-shadow: none !important;
}

.rbt-input:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}