$ColorWhite: #ffffff;

$Color_Font_Light: #ffffff;
$Color_Font_Gray: #919392;
$Color_Font_LightGray: #dddddd;
$Color_Font_Dark: #000000;
$Color_Font_Grey6: #F6F6F6;
$Color_Blue_Dark: #3375C0;

$BorderColor_Input: #dddddd;
$BorderColor_Input_focused: #919392;
$Color_Input_placeholder: #c7c7c7;
$BgColor_Input_disabled: #f6f6f6;

$Color_Button: #ffffff;
$BgColor_Button_disabled: #dddddd;

$BgColor_ButtonPrimary: #467fcf;
$BgColor_ButtonPrimary_hover: #3e74c0;

$BgColor_RockerSwitch_Gray: #d2d2d2;

$BgColor_ButtonSubmit: #2dcc70;
$BgColor_ButtonSubmit_hover: #28bc66;

$BgColor_HeaderRot: #a3060a;
$BgColor_Wert14LogoRot: #c62026;
$Color_TextTitel: #121618;

$Color_MapSelected: #ffde00;
$Color_MapSecondBuilding: #f08200;
$Color_MapMainBuilding: #3a89fc;

$BorderColor_UI: #dddddd;

$Color_Ok: #27cb6c;
:export { Color_Ok: $Color_Ok; }
$Color_Warning: #dadada;
:export { Color_Warning: $Color_Warning; }
$Color_NotOk: #ffcd37;
:export { Color_NotOk: $Color_NotOk; }