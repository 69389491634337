div.la-ball-clip-rotate.loadingBall {
    color: #467fcf;
    z-index: 1;
    /* margin: 62px auto */
    position: absolute;
    right: 50%;
    bottom: 50%;
    height: 0px;
}

.loadingContainer {
    width: 100%;
}
.loadingContainer .OverlayVisible {
    background-color: #fff;
    opacity: 0.5;
    pointer-events: none;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
}

.loadingContainer div.sticky {
    margin: 0 auto;
    position: sticky;
    right: initial;
    bottom: initial;
}