.Toastify__toast--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.Toastify__close-button--error {
    color: #721c24;
}

.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: .75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}

.Toastify__toast--success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.Toastify__close-button--success {
    color: #3c763d;
}